<template>
  <div class="task-detail" :style="detailStyle">
    <div class="detail-header">
      <div class="detail-header-name">
        <Poptip placement="bottom" v-model="showPoptip" class="fixed" @on-popper-show="poptipShow">
          <div class="project-btn hand">
            <span class="project-name" @click.stop="showPoptip=true">{{ showProjectName }}-{{ showGroupName }}</span>
          </div>
          <template #content>
            <div style="display: flex;">
              <Tree style="min-width: 150px;" :data="projectList" children-key="list" @on-select-change="onProjectTreeSelect" :render="renderContent"></Tree>
              <CellGroup class="toast-tree-group">
                <Cell
                  v-for="(group, index) in groupList"
                  :key="index"
                  :title="group.name"
                  @click="onGroupClick(group)" />
              </CellGroup>
            </div>
          </template>
        </Poptip>
        <Dropdown trigger="click" transfer>
          <XIcon :iconClass="showPriorityIcon" size="18" class="priority-icon"/>
          <template #list>
            <DropdownMenu class="dropdown-list" >
              <DropdownItem v-for="value in priorityList" :key="value" @click="onLevelClick(value)">
                <XIcon :iconClass="value.icon" />&nbsp;
                <span :class="currentTask.level==value.index ? 'primary-color' : ''">{{ value.title }}</span>
              </DropdownItem>
            </DropdownMenu>
          </template>
        </Dropdown>
        <!-- <div class="icon-bg">
          <XIcon iconClass="schedule" style="color: #979EA7;" @click="onAttachClick" />
        </div> -->
        <!-- <div class="icon-bg">
          <XIcon iconClass="location" style="color: #979EA7;" @click="(showMap=true)"/>
        </div> -->
        <!-- <Dropdown trigger="click" @on-visible-change="onTagChanged">
          <div class="icon-bg">
            <XIcon iconClass="tag" />
          </div>
          <template #list>
            <DropdownMenu style="width: 140px;">
              <template
                v-for="item in tagList"
                :key="item.tagIdStr" >
                <Dropdown
                  v-if="item.list && item.list.length > 0"
                  placement="right-start">
                  <DropdownItem>
                    <Checkbox :modelValue="tagSelected(item)" @click.stop="onTagBoxClick(item)"></Checkbox>
                    <Text style="width: 75px;" ellipsis>{{ item.tagName }}</Text>
                    <Icon type="ios-arrow-forward"></Icon>
                  </DropdownItem>
                  <template #list>
                    <DropdownMenu>
                      <DropdownItem v-for="subItem in item.list" :key="subItem.tagIdStr">
                        <Checkbox :modelValue="tagSelected(subItem)" @click.stop="onTagBoxClick(subItem)"></Checkbox>
                        <Text style="width: 75px;" ellipsis>{{ subItem.tagName }}</Text>
                      </DropdownItem>
                    </DropdownMenu>
                  </template>
                </Dropdown>
                <DropdownItem v-else>
                  <Checkbox :modelValue="tagSelected(item)" @click.stop="onTagBoxClick(item)"></Checkbox>
                  <Text style="width: 75px;" ellipsis>{{ item.tagName }}</Text>
                </DropdownItem>
              </template>
            </DropdownMenu>
          </template>
        </Dropdown> -->
        <!-- <div class="icon-bg">
          <XIcon iconClass="font" @click="onFontClick" />
        </div> -->
        <!-- <Poptip :width="220" trigger="click" placement="bottom">
          <div class="icon-bg">
            <XIcon iconClass="line" />
          </div>
          <template #content>
            <div>
              <div class="space-between">
                <div
                  class="size-bg"
                  v-for="item, index in lineList"
                  :key="item"
                  :style="{marginLeft: index==0 ? '0px' : '8px'}"
                  @click="onLineClick(item.icon)" >
                  <XIcon :iconClass="item.icon" />
                  <br>
                  <span style="lineHeight: 20px">{{ item.title }}</span>
                </div>
              </div>
              <Divider style="margin: 10px 0;" />
              <span style="fontSize: 12px; color: #A6A6A6;">线条颜色</span>
              <Grid :col="6" :border="false" padding="5px 5px">
                <GridItem
                  style="text-align: center;"
                  v-for="color in lineColors"
                  :key="color"
                  @click="lineColor=color"
                >
                  <div :class="'color-item '+(color==lineColor?'select':'')" :style="{backgroundColor: color,}"></div>
                </GridItem>
              </Grid>
            </div>
          </template>
        </Poptip> -->
        <!-- <div class="icon-bg">
          <XIcon iconClass="more" @click="onMoreClick"/>
        </div> -->
      </div>
      <div v-if="currentTask.taskType==2 && currentTask.endTime>0" class="project-complate">
        <img src="../assets/habit.png" width="" height="48">
        <span style="margin-left:12px;">习惯已结束</span>
      </div>
      <div class="project-title">
        <XIcon v-if="currentTask.taskType==0" :iconClass="checkName" :style="checkStyle" @mousedown.stop="onCompleteClick" class="check"/>
        <XIcon v-if="currentTask.taskType==1" iconClass="file" />
        <!-- <Circle :percent="todayPercent(currentTask)" stroke-color="#ff5733" v-if="currentTask.taskType==2" :size="20"
                @click="updateClock(currentTask)">
          <Icon v-if="todayPercent(currentTask) == 100" type="ios-checkmark" size="20" style="color:#ff5733"></Icon>
          <Icon v-if="todayGiveUp(currentTask)" type="ios-close" size="20" style="color:#999"></Icon>
        </Circle> -->
        <div 
          v-if="currentTask.taskType==2"
          style="padding-top: 3px; cursor: pointer;"
          @click.stop="updateClock(currentTask)">
          <Icon 
            v-if="todayPercent(currentTask) == 100" 
            type="md-checkmark" 
            :size="20" 
            style="color:#3D87DA">
          </Icon>
          <Icon 
            v-else-if="todayGiveUp(currentTask)" 
            type="md-close" 
            size="20" 
            style="color:#999">
          </Icon>
          <Circle 
            v-else
            trail-color="var(--vp-c-text-3)"
            :percent="todayPercent(currentTask)" 
            stroke-color="#3D87DA" 
            :size="20">
          </Circle>
        </div>
        <Input
          class="title"
          :class="ifocus ? 'ifocus' : ''"
          v-model="currentTask.title"
          :border="false"
          placeholder="无标题"
          @on-blur="onTitleBlur"
          @on-focus="onTitleFocus"
        />
      </div>
      <Space
        size="large"
        style="margin: 8px 0px; display: flex; flex-wrap: wrap; gap: 6px;"
        v-if="(currentTask.hasNotice || currentTask.hasRepeat || currentTask.fields || currentTask.startTime)"
      >
        <XIconButton
          v-if="currentTask.startTime"
          name="schedule"
          :title="showTime"
          :titleColor="timeTitleColor"
          :iconColor="timeColor"
          @click="onAttachClick" />
        <template v-if="currentTask.hasNotice && notices.length > 0">
          <XIconButton name="task_alert" :title="showNoticeTitle" @click="onAttachClick" />
        </template>
        <XIconButton v-if="currentTask.hasRepeat" name="task_cyclic" :title="repeat.msg" @click="onAttachClick" />
        <template
            v-if="currentTask.taskType == 2"
        >
          <XIconButton
              v-if="repeat.repeatMode != 15"
              name="task_cyclic"
              :title="`${currentTask.habitDTO.habitDayNum}${currentTask.habitDTO.habitUnit }/天`"
              titleColor="#999"
              @click="onAttachClick" />
        </template>
        <!-- <XIconButton v-if="currentTask.fields" name="task_accessory" title="附件" /> -->
      </Space>
      <Space direction="vertical" style="width: 100%;">
        <!-- <Text class="sub-title" ellipsis>{{ '这里说具体任务的描述，你想说点什么就说点什么' }}</Text> -->
        <Text v-if="currentTask.addressIdStr" class="sub-title" ellipsis>
          <XIcon size="16" iconClass="location" style="color: #3D87DA" />
          {{ showAddress }}
        </Text>
        <div v-if="currentTask.tagList && currentTask.tagList.length > 0">
          <Tag
            style="border-radius:13px; border:none;"
            v-for="tag in showTagList"
            :key="tag.tagIdStr"
            :color="showTagColor(tag.color) + '40'">
            <div style="display: flex; align-items: center;">
              <span :style="{ color: showTagColor(tag.color) }">{{ tag.tagName }}</span>
              <Icon style="line-height: 24px;" type="ios-close" @click="onTagClose(tag)" />
            </div>
          </Tag>
        </div>
        <div
          v-if="currentTask.parentTaskIdStr"
          style="color: #999999; font-size: 12px; display: flex; margin-top: 6px;"
          class="hand"
          @click="onParentTaskClick">
          <span style="margin-right: 4px;">父任务： {{ parentTask.title }}</span>
          <XIcon iconClass="arrow_double" />
        </div>
      </Space>
    </div>
    <div class="rich-text-wrapper">
      <XRichText ref="richText" :task="task" :isToast="true" />
      <div v-if="(subTaskList.length > 0)" class="sub-task">
        <div v-for="task in subTaskList" :key="task.taskIdStr" style="display: flex; align-items: center; padding: 5px;">
          <XIcon
            v-if="task.taskType==0"
            :iconClass="checkClass(task)"
            :style="taskCheckStyle(task)"
            @click.stop="onSubTaskCheck(task)"/>
          <XIcon v-if="task.taskType==1" iconClass="file" style="width: 20px;" />
          <Circle trail-color="var(--vp-c-text-3)" :percent="todayPercent(task)" stroke-color="#ff5733" v-if="task.taskType==2" :size="20"
                  @click="updateClock(task)">
            <Icon v-if="todayPercent(task) == 100" type="ios-checkmark" size="20" style="color:#ff5733"></Icon>
            <Icon v-if="todayGiveUp(task)" type="ios-close" size="20" style="color:#999"></Icon>
          </Circle>
          <Input
            class="title"
            v-model="task.title"
            :border="false"
            placeholder="无标题"
            @on-blur="onSubTaskBlur(task)"
          />
          <Icon
            class="hand"
            type="ios-share-alt-outline"
            style="font-size: 20px; color: #3D87DA; margin-right: 5px;"
            @click="onSubTaskClick(task)"/>
          <Icon
            class="hand"
            type="ios-remove-circle-outline"
            style="font-size: 20px; color: #FA9300"
            @click="onSubTaskRemove(task)"/>
        </div>
      </div>
    </div>
    <XAttachModal
      :show="showModal"
      :data="{...attach,attachType:currentTask.taskType}"
      @onModalChanged="onModalChanged"
      @onModalOk="onModalOk"/>
    <Modal
      draggable
      sticky
      scrollable
      :mask="false"
      :width="620"
      v-model="showMap"
      title="选择地图"
      @on-ok="onMapModalOk">
      <Text style="margin-bottom: 6px;" ellipsis :ellipsis-config="{ tooltip: true }">{{ location.address || '点击地图选择位置' }}</Text>
      <XMap v-if="showMap" @onSelected="onMapSelected" />
    </Modal>
  </div>
</template>

<script>

import { colorFromValue, formatTime, timeExpire } from '@/common/util';
import XIconButton from './XIconButton';
import XRichText from './XRichText';
import XAttachModal from "@/components/XAttachModal";
import XMap from "@/components/XMap";
import {
  projectList,
  taskList,
  groupList,
  noticeDetail,
  repeatDetail,
  tagList,
  taskUpdate,
  addressAdd,
  addressList,
  noticeAdd,
  noticeDelete,
  repeatAdd,
  repeatDelete, clockedUpdate, clockedAdd,
} from '@/common/api';
import {dayjs} from "element-plus";

const priorityList = [{
  title: '高优先级',
  icon: 'flag_high',
  index: 3
}, {
  title: '中优先级',
  icon: 'flag_middle',
  index: 2
}, {
  title: '低优先级',
  icon: 'flag_low',
  index: 1
}, {
  title: '无优先级',
  icon: 'flag_normal',
  index: 0
}];

const lineList = [{
  title: '实线',
  icon: 'line_solid'
}, {
  title: '虚线',
  icon: 'line_dotted'
}, {
  title: '点线',
  icon: 'line_point'
}];

const lineColors = [
  '#000000', '#383838', '#808080', '#A6A6A6', '#CCCCCC', '#E6E6E6',
  '#FF5733', '#FF7E33', '#FFA733', '#FFC933', '#FFE033', '#F9ECC7',
  '#6E8A31', '#6CB85D', '#43CF7C', '#71AFBD', '#93CDF9', '#CCE4FB',
];

export default {
  components: {
    XIconButton,
    XRichText,
    XAttachModal,
    XMap
  },
  props: {
    task: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ifocus: false,
      showPoptip: false,
      baseProjectList: [],
      projectList: [],
      groupList: [],
      taskList: [],
      subTaskList: [],
      notices: [],
      repeat: {},
      addressList: [],
      tagList: [],
      baseTagList: [],
      showModal: false,
      attach: {},
      showMap: false,
      location: {},
      lineList,
      lineColors,
      lineColor: '#000000',
      dataList: [{"align":0,"check":false,"indent":0,"number":0,"position":0,"quote":false,"spanList":[{"background":false,"bold":false,"color":-16711936,"content":"1234","fontSize":77,"italic":false,"strikethrough":true,"underlined":false},{"background":true,"bold":true,"color":-65536,"content":"拉呢数据","fontSize":39,"italic":false,"strikethrough":true,"underlined":true}],"spanText":"1234拉呢数据","textType":0,"type":1}],
      priorityList: priorityList,
      copyData:null,
    }
  },
  mounted() {
    this.loadProjectList();
    this.loadTaskList();
    this.loadGroupList();
    this.loadTagData();
    this.loadNotices();
    this.loadAddressList();
  },
  computed: {
    detailStyle() {
      let height = document.documentElement.clientHeight - 306;
      return {
        minHeight: '500px',
        maxHeight: (height > 500 ? height : 500) + 'px'
      };
    },
    currentTask() {
      return this.copyData || this.task;
    },
    parentTask() {
      let task = this.taskList.find(item => item.taskIdStr == this.currentTask.parentTaskIdStr);
      return task ?? {};
    },
    showProjectName() {
      let project = this.baseProjectList.find(item => item.projectIdStr == this.currentTask.projectIdStr);
      if (project) {
        return project.name;
      }
      return '';
    },
    showGroupName() {
      if (this.currentTask.groupIdStr) {
        let group = this.groupList.find(item => item.groupIdStr == this.currentTask.groupIdStr);
        if (group) {
          return group.name;
        }
      }
      return '未分组';
    },
    checkStyle() {
      const colors = {
        0: 'black',
        3: '#F12121',
        2: '#FA9300',
        1: '#5877FA'
      };
      let key = this.currentTask.level || 0;
      let color = colors[key];
      return {
        color,
        fontSize: '20px',
      };
    },
    checkName() {
      if (this.currentTask.giveUp) {
        return 'check_invalid';
      } else if (this.currentTask.completeTime > 0) {
        return 'check_complete';
      }
      return 'check';
    },
    showAddress() {
      for (let i = 0; i < this.addressList.length; i++) {
        const element = this.addressList[i];
        if (element.addressIdStr == this.currentTask.addressIdStr) {
          return element.poiAddress;
        }
      }
      return '';
    },
    showTagList() {
      let list = [];
      this.currentTask.tagList.forEach(e1 => {
        this.baseTagList.forEach(e2 => {
          if (e1 == e2.tagIdStr) {
            list.push(e2);
          }
        });
      });
      return list;
    },
    timeTitleColor() {
      return timeExpire(this.currentTask.startTime, this.currentTask.endTime) ? '#999' : '#3D87DA';
    },
    timeColor() {
      return timeExpire(this.currentTask.startTime, this.currentTask.endTime) ? '#979EA7' : '#3D87DA';
    },
    timeExpired() {
      let nowTime = new Date().getTime();
      return this.currentTask.endTime > 0 && this.currentTask.endTime < nowTime;
    },
    showTime() {
      return formatTime(this.currentTask.startTime, this.currentTask.endTime, this.currentTask.completeTime == 0);
    },
    showPriorityIcon() {
      let iconName = '';
      this.priorityList.forEach(element => {
        if (element.index == this.currentTask.level) {
          iconName = element.icon;
        }
      });
      return iconName;
    },
    showNoticeTitle() {
      if (this.notices && this.notices.length > 0) {
        return this.notices.map(item => item.msg).join(',');
      }
      return '';
    },
    listFlag() {
      return this.$store.state.listFlag;
    },
  },
  methods: {
    renderContent(h, { data }) {
      return h('span', [
        h('span', {
          style: {
            display: 'flex',
            alignItems: 'center'
          }
        },[
          h('img', {
            src: data.iconUrl,
            style: {
              width: '14px', // 调整图标的大小
              height: '14px', // 调整图标的大小
              marginRight: '8px'
            }
          }),
          h('span', data.title)
        ])
      ]);
    },
    loadProjectList() {
      projectList().then(res => {
        if (res) {
          console.log(res)
          this.packageProject(res);
        }
      });
    },
    packageProject(list) {
      let normalList = [];
      list.forEach(element => {
        if (element.projectType == 0 && element.projectIdStr != ('-1' + this.$store.state.user.userId)) {
          element.title = element.name;
          normalList.push(element);
        }
      });
      this.baseProjectList = normalList;
      this.projectList = this.dgProjectTree(null, null, normalList);
    },
    dgProjectTree(id, name, list) {
      let children = [];
      list.forEach(item => {
        if (item.parentProjectIdStr === id) {
          console.log(item)
          item.parentProjectName = name;
          item.list = this.dgProjectTree(item.projectIdStr, item.name, list);
          children.push(item);
          children = children.sort((a, b) => b.positionWeight - a.positionWeight);
        }
      });
      return children;
    },
    loadTaskList() {
      let projectId = this.currentTask.projectIdStr;
      taskList(projectId).then(res => {
        if (res) {
          this.taskList = res;
        }
      });
    },
    searchSubTask() {
      let list = [];
      this.taskList.forEach(element => {
        if (element.parentTaskIdStr == this.$route.params.task) {
          list.push(element);
        }
      });
      this.subTaskList = list;
    },
    loadGroupList() {
      let projectId = this.currentTask.projectIdStr;
      groupList(projectId).then(res => {
        if (res) {
          this.groupList = res.sort((a, b) => b.positionWeight - a.positionWeight);
          console.log(this.groupList)
        }
      });
    },
    loadTagData() {
      tagList().then(res => {
        if (res) {
          this.baseTagList = res;
          this.tagList = this.dgTagTree(null, null, res);
        }
      });
    },
    dgTagTree(id, name, list) {
      let children = [];
      list.forEach(item => {
        if (item.parentTagIdStr === id) {
          item.parentTagName = name;
          item.list = this.dgTagTree(item.tagIdStr, item.tagName, list);
          children.push(item);
        }
      });
      return children.sort((a, b) => b.positionWeight - a.positionWeight);
    },
    loadNotices() {
      if (this.currentTask.taskIdStr && this.currentTask.hasNotice) {
        noticeDetail(this.currentTask.taskIdStr).then(res => {
          if (res) {
            this.notices = res;
          }
        });
      } else {
        this.notices = [];
      }
      if (this.currentTask.taskIdStr && this.currentTask.hasRepeat) {
        repeatDetail(this.currentTask.taskIdStr).then(res => {
          if (res) {
            this.repeat = res;
          }
        });
      } else {
        this.repeat = {};
      }
    },
    loadAddressList() {
      addressList().then(res => {
        if (res) {
          this.addressList = res;
        }
      });
    },

    poptipShow() {
      if (this.currentTask.projectIdStr) {
        groupList(this.currentTask.projectIdStr).then(res => {
          if (res) {
            this.groupList = res.sort((a, b) => b.positionWeight - a.positionWeight);
          }
        });
      }
    },
    onSubTaskCheck(task) {
      if (task.giveUp || task.completeTime > 0) {
        task.completeTime = 0;
        task.giveUp = false;
      } else {
        task.completeTime = Date.now();
        if (!task.originTime) {
          task.originTime = task.startTime;
        }
      }
      taskUpdate(task);
    },
    onSubTaskBlur(task) {
      taskUpdate(task);
    },
    onSubTaskClick(task) {
      this.$router.push('/manager/work/' + this.currentTask.projectIdStr + '/' + task.taskIdStr);
    },
    onSubTaskRemove(task) {
      this.$store.commit('updateListFlag', {
        key: 'delete',
        value: task
      });
    },
    checkClass(task) {
      if (task.giveUp) {
        return 'check_invalid';
      } else if (task.completeTime > 0) {
        return 'check_complete';
      }
      return 'check';
    },
    taskCheckStyle(task) {
      const colors = {
        0: 'black',
        3: '#F12121',
        2: '#FA9300',
        1: '#5877FA'
      };
      let key = task.level || 0;
      let color = colors[key];
      return {
        color,
        width: '20px'
      };
    },
    onTagClose(tag) {
      let index = this.currentTask.tagList.indexOf(tag.tagIdStr);
      this.currentTask.tagList.splice(index, 1);
      if (this.currentTask.taskIdStr) {
        taskUpdate(this.currentTask);
      }
    },
    onProjectTreeSelect(list) {
      if (list && list.length > 0) {
        this.showPoptip = false;
        let project = list[0];
        if (this.currentTask.projectIdStr != project.projectIdStr) {
          this.currentTask.projectIdStr = project.projectIdStr;
          this.currentTask.parentTaskId = null;
          this.currentTask.parentTaskIdStr = null;
          this.currentTask.groupIdStr = null;
        }
        if (this.currentTask.taskIdStr) {
          taskUpdate(this.currentTask).then((res) => {
            if (res) {
              this.$Message.success(`${this.currentTask.title} 已移动到 ${project.name}`);
            }
          });
        }
      }
    },
    onGroupClick(group) {
      if (this.currentTask.groupIdStr != group.groupIdStr) {
        this.showPoptip = false;
        this.currentTask.groupIdStr = group.groupIdStr;
        if (this.currentTask.taskIdStr) {
          taskUpdate(this.currentTask).then((res) => {
            if (res) {
              this.$Message.success(`${this.currentTask.title} 已移动到 ${group.name}`);
            }
          });
        }
      }
    },
    onParentTaskClick() {
      this.$router.push('/manager/work/' + this.$route.params.project + '/' + this.currentTask.parentTaskIdStr).then(() => {
        this.$store.commit('taskChanged', this.parentTask);
      });
    },
    onLevelClick(priority) {
      this.currentTask.level = priority.index;
      if (this.currentTask.taskIdStr) {
        taskUpdate(this.currentTask);
      }
    },
    onCompleteClick() {
      let task = this.currentTask;
      if (task.giveUp || task.completeTime > 0) {
        task.completeTime = 0;
        task.giveUp = false;
      } else {
        task.completeTime = Date.now();
        if (!task.originTime) {
          task.originTime = task.startTime;
        }
      }
      if (this.currentTask.taskIdStr) {
        taskUpdate(this.currentTask).then((res) => {
          if (res) {
            if (!this.currentTask.giveUp && this.currentTask.completeTime > 0) {
              this.$Message.success('恭喜，任务已完成');
            }
          }
        });
      }
    },
    showTagColor(value) {
      return value ? colorFromValue(value) : '#3D87DA';
    },

    onAttachClick() {
      this.attach = {
        startTime: this.currentTask.startTime,
        endTime: this.currentTask.endTime,
        notices: [...this.notices],
        repeat: this.repeat,
        habit: this.currentTask.habitDTO,
        noticeInfo:this.currentTask.noticeInfo,
      };
      this.showModal = true;
    },
    onModalChanged(value) {
      this.showModal = value;
    },
    async onModalOk(attach) {
      this.currentTask.startTime = attach.startTime;
      this.currentTask.originTime = attach.startTime;
      this.currentTask.endTime = attach.endTime;
      this.currentTask.noticeInfo = attach.noticeInfo;
      if (!this.currentTask.taskIdStr) {
        this.notices = attach.notices;
        this.repeat = attach.repeat;
        this.currentTask.notices = attach.notices;
        this.currentTask.repeat = attach.repeat;
        this.currentTask.hasNotice = this.notices.length > 0;
        this.currentTask.repeat = repeat && Object.keys(repeat).length > 0;
        return;
      }
      await taskUpdate(this.currentTask);
      let addList = [];
      let deleteList = [];
      this.attach.notices.forEach(item => {
        let hasSome = attach.notices.some(subItem =>
          item.mode == subItem.mode &&
          item.day == subItem.day &&
          item.hour == subItem.hour &&
          item.minute == subItem.minute);
        if (!hasSome) {
          deleteList.push(item);
        }
      });
      attach.notices.forEach(item => {
        let hasSome = this.attach.notices.some(subItem =>
          item.mode == subItem.mode &&
          item.day == subItem.day &&
          item.hour == subItem.hour &&
          item.minute == subItem.minute);
        if (!hasSome) {
          addList.push(item);
        }
      });
      for (let i = 0; i < addList.length; i++) {
        const notice = addList[i];
        notice.taskIdStr = this.currentTask.taskIdStr;
        await noticeAdd(notice);
      }
      for (let i = 0; i < deleteList.length; i++) {
        const notice = deleteList[i];
        await noticeDelete(notice.taskNoticeIdStr);
      }
      this.notices = attach.notices;
      this.currentTask.hasNotice = this.notices && this.notices.length > 0;

      let repeat = attach.repeat;
      if (repeat && Object.keys(repeat).length > 0) {
        repeat.taskIdStr = this.currentTask.taskIdStr;
        await repeatAdd(repeat);
      } else if (this.attach.repeat && Object.keys(this.attach.repeat).length > 0) {
        await repeatDelete(repeat.taskRepeatIdStr);
      }
      this.repeat = repeat;
      this.currentTask.hasRepeat = repeat && Object.keys(repeat).length > 0;
      // this.loadNotices();
    },
    onTitleBlur() {
      this.ifocus = false
      if (this.currentTask.taskIdStr) {
        taskUpdate(this.currentTask);
      }
    },
    onTitleFocus(){
      this.ifocus = true
    },
    onMapSelected(value) {
      this.location = value;
    },
    onMapModalOk() {
      if (this.location.lng && this.location.lat) {
        addressAdd({
          positionWeight: 1,
          poiAddress: this.location.address,
          latitude: this.location.lat,
          longitude: this.location.lng,
          totalAddress: this.location.address,
          userId: this.$store.state.user.userId,
        }).then(res => {
          if (res) {
            addressList().then(res => {
              if (res) {
                this.addressList = res;
              }
            });
            this.currentTask.addressIdStr = res;
            if (this.currentTask.taskIdStr) {
              taskUpdate(this.currentTask);
            }
          }
        });
      }
    },
    tagSelected(item) {
      return (this.currentTask.tagList || []).indexOf(item.tagIdStr) > -1;
    },
    onTagBoxClick(item) {
      if (!this.currentTask.tagList) {
        this.currentTask.tagList = [];
      }
      let index = this.currentTask.tagList.indexOf(item.tagIdStr);
      if (index > -1) {
        this.currentTask.tagList.splice(index, 1);
      } else {
        this.currentTask.tagList.push(item.tagIdStr);
      }
    },
    onTagChanged(visible) {
      if (!visible && this.currentTask.taskIdStr) {
        taskUpdate(this.currentTask);
      }
    },
    onFontClick() {
      this.$refs.richText.showTool();
    },
    onLineClick(type) {
      let map = {
        'line_solid': 'solid',
        'line_dotted': 'dashed',
        'line_point': 'dotted'
      }
      this.$refs.richText.addLine(map[type] || 'solid', this.lineColor);
    },
    onMoreClick(e) {
      this.$store.commit('updateMenuData', {
        event: e,
        task: this.currentTask
      });
    },
    async updateClock(task, time, needPop){
      console.log("时间",time)
      if (!time){
        time = dayjs().valueOf()
      }
      //判断是否完成今日的打卡
      //没有完成  根据配置直接更新打卡，不用弹框 采用add方式
      let lastItem = this.todayLastItem(task, time);
      let clock = {
        "clockedMood": "",//打卡表情   cry哭  nervous紧张  normal正常  good好的 happy高兴
        "completeNum": 1,
        "clockedLog": "",
        "clockedDetail": "[{\"content\":\"1/1次\",\"time\":1694620651063}]",
        "giveUp": false,
        "updateTime": new Date().getTime(),
      }
      if (lastItem){
        clock.clockedHistoryId = lastItem.clockedHistoryId;
        clock.clockedHistoryIdStr = lastItem.clockedHistoryIdStr;
        clock.totalNum = lastItem.totalNum;
        clock.time = lastItem.time
        clock.completeNum = lastItem.completeNum
        clock.clockedMood = lastItem.clockedMood
        clock.clockedLog = lastItem.clockedLog
        clock.clockedDetail = lastItem.clockedDetail
      }else{
        clock.totalNum = task.habitDTO.habitDayNum;
        clock.time =  dayjs(time).valueOf();
      }

      clock.taskIdStr = task.taskIdStr;
      clock.taskId = task.taskId;
      if(this.todayPercent(task) != 100){
        //配置分为3种
        if (task.habitDTO.habitType == 0){
          if (lastItem){
            if (needPop){
              this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
              this.$store.commit('updateShowDialog', true);
              return
            }
            clock.completeNum = clock.completeNum + task.habitDTO.habitOnceNum
            if (clock.completeNum > clock.totalNum){
              clock.completeNum = clock.totalNum;
            }
            if (clock.completeNum == clock.totalNum){
              clock.complete = true;
            }else{
              clock.complete = false;
            }
            const array = JSON.parse(lastItem.clockedDetail)
            array.push({content:`${clock.completeNum}/${clock.totalNum}${task.habitDTO.habitUnit}`,
              time:dayjs().valueOf()})
            clock.clockedDetail = JSON.stringify(array);
            await clockedUpdate(clock)
          }else{
            if (needPop){
              this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
              this.$store.commit('updateShowDialog', true);
              return
            }
            clock.completeNum = task.habitDTO.habitOnceNum
            if (clock.completeNum > clock.totalNum){
              clock.completeNum = clock.totalNum;
            }
            if (clock.completeNum == clock.totalNum){
              clock.complete = true;
            }else{
              clock.complete = false;
            }
            const array = []
            array.push({content:`${clock.completeNum}/${clock.totalNum}${task.habitDTO.habitUnit}`,
              time:dayjs().valueOf()})
            clock.clockedDetail = JSON.stringify(array);
            await clockedAdd(clock)
          }
        }else if (task.habitDTO.habitType == 1){
          this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
          this.$store.commit('updateShowDialog', true);
          return
        }else if (task.habitDTO.habitType == 2){
          if (lastItem){
            if (needPop){
              this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
              this.$store.commit('updateShowDialog', true);
              return
            }
            clock.completeNum = clock.totalNum;
            clock.complete = true;
            const array = JSON.parse(lastItem.clockedDetail)
            array.push({content:`${clock.completeNum}/${clock.totalNum}${task.habitDTO.habitUnit}`,
              time:dayjs().valueOf()})
            clock.clockedDetail = JSON.stringify(array);
            await clockedUpdate(clock)
          }else{
            if (needPop){
              this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
              this.$store.commit('updateShowDialog', true);
              return
            }
            clock.completeNum = clock.totalNum;
            clock.complete = true;
            const array = []
            array.push({content:`${clock.completeNum}/${clock.totalNum}${task.habitDTO.habitUnit}`,
              time:dayjs().valueOf()})
            clock.clockedDetail = JSON.stringify(array);
            await clockedAdd(clock)
          }
        }
        //1自动按照量打卡 每次都是add一个配置量的打卡
        //2手动指定量打卡  弹框输入量打卡  add
        //3全部完成 每次都是add或者update一个全部量的打卡

        if(task.habitDTO.autoShowLog){//弹框
          this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
          this.$store.commit('updateShowDialog', true);
        }
      }else{
        this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
        this.$store.commit('updateShowDialog', true);
        return
      }
      //完成打卡的  根据当前的最后一次打卡记录做更新操作 弹出弹框
      //更新列表里的这个数据，刷新页面
      let copyData = JSON.parse(JSON.stringify(task));
      this.$store.commit('updateListFlag', {
        key: 'update',
        value: copyData
      });
    },
    todayLastItem(task, time){
      let date = dayjs(time).format('YYYYMMDD');
      let array = [];
      let item = null;
      if (task.taskType == 2 && task.clockedHistoryDTOList) {
        array = task.clockedHistoryDTOList.filter(item => {
          return dayjs(item.time).format('YYYYMMDD') == date;
        });
        if (array.length > 0){
          item = array[array.length-1]
        }
      }
      return item;
    },
    todayNum(task){
      const date = dayjs().format('YYYYMMDD');
      let array = [];
      let completeNum = 0;
      if (task.taskType == 2 && task.clockedHistoryDTOList){
        array = task.clockedHistoryDTOList.filter(item=>{
          return dayjs(item.time).format('YYYYMMDD')== date;
        });
        completeNum = array.reduce((pre,item)=>{
          return pre + item.completeNum;
        },0)
      }
      return completeNum;
    },
    todayPercent(task){
      if (task.taskType  == 2 ){
        let percent = this.todayNum(task) * 100 / task.habitDTO.habitDayNum;
        if(percent>100){
          percent = 100
        }
        return percent;
      }else{
        return 0;
      }
    },
    todayGiveUp(task){
      const date = dayjs().format('YYYYMMDD');
      let array = [];
      if (task.taskType == 2 && task.clockedHistoryDTOList){
        array = task.clockedHistoryDTOList.filter(item=>{
          return dayjs(item.time).format('YYYYMMDD')== date;
        });
        return array.some(item=>{
          let records = []
          try {
            records = JSON.parse(item.clockedDetail);
          }catch (e){
            console.log(e)
          }
          if (records.length){
            const content = records[records.length-1].content;
            if (content =='放弃了打卡'){
              return true;
            }
          }
        })
      }
      return false;
    }
  },
  watch:{
    listFlag(n) {
      if (n.key == 'update') {
        let projectId = this.currentTask.projectIdStr;
        taskList(projectId).then(res => {
          if (res) {
            const findItem = res.find(item=>item.taskId == n.value.taskId)
            if (findItem){
              this.copyData = findItem;
            }
          }
        });
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .task-detail {
    color: var(--vp-c-text-1);
    display: flex;
    flex-direction: column;
    padding-left: 3px;
    max-height: 700px!important;
    .detail-header {
      :deep(.ivu-input) {
        color: var(--vp-c-text-1);
        background-color: var(--vp-c-bg-soft);
        font-weight: 600;
      }
      padding: 0px 20px 8px 20px;
      padding-bottom: 12px;
      .detail-header-name{
        display: flex;
        justify-content: space-between;
        .toast-tree-group {
          min-width: 150px;
          border-left: 1px solid;
          border-color: var(--vp-c-divider);
          color: var(--vp-c-text-2);
          :deep(.ivu-cell){
            color: var(--vp-c-text-2);
            border-radius: 8px;
            &:hover{
              color: var(--vp-c-text-1);
            }
          }
        }
        .project-name{
          opacity: (0.75);
          color: var(--vp-c-text-1);
          &:hover {
            opacity: (1);
            transform: scale(1.1);
          }
        }
        .priority-icon{
          transition: all 0.2 linear;
          &:hover{
            transform: scale(1.1);
          }
        }
      }
      .project-complate{
        display: flex;
        align-items: center; 
        margin-top: 12px;
      }
      .project-title{
        display: flex;
        align-items: center; 
        margin-top: 15px;
        // border-bottom: 0.5px solid var(--vp-c-divider);
      }
      .icon-bg {
        // padding: 0px 4px;
        border-radius: 6px;
        background: #FAFAFA;
        height: 24px;
      }
      .project-btn {
        border-radius: 6px;
        // color: #979EA7;
        color: var(--vp-c-text-1);
        font-size: 14px;
        text-overflow: ellipsis;
      }
      .sub-title {
        color: #609ee2;
        font-size: 13px;
      }
      .check {
        &:hover {
          background-color: #9191918a;
          border-radius: 3px;
        }
      }
    }
    .rich-text-wrapper{
      flex: 1;
      display: flex;
      overflow: auto; 
      flex-direction: column;
    }
    .size-bg {
      padding: 6px;
      border: 1px solid #E5E5E5;
      background-color: #FAFAFA;
      text-align: center;
      border-radius: 6px;
      cursor: pointer;
      &.select {
        border-color: transparent;
        background-color: #BDDBFC;
      }
    }
    .color-item {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      cursor: pointer;
      &.select {
        border: 3px solid #BDDBFC;
      }
    }
    .sub-task {
      background-color: #fafafa;
      border-radius: 8px;
      margin: 20px;
      :deep(.ivu-input) {
        background-color: #fafafa;
      }
    }
    .title {
      &.ifocus {
        :deep(.ivu-input) {
          border-color: #1b9aee;
          background-color: var(--vp-c-bg);
          box-shadow: 0px 1px 12px 0px #00000011;
          color: var(--vp-c-text-1);
        }
      }
      :deep(.ivu-input) {
        resize: none;
        margin: 0 5px;
        border-radius: 8px;
        border-style: dashed;
        &:hover {
          border-color: #1b9aee;
        }
      }
    }
  }
</style>
